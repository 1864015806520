<template>
  <Connect class="connect_edit">
    <ul class="list_connect">
      <li v-for="item in dataList" :key="item.requestCid" :class="{ disabled: isDisabled(item) }">
        <span class="ico_account ico_connect_sm" />
        <button
          v-if="!isDisabled(item)"
          type="button"
          class="btn_connect"
          @click="$emit('onClickConnect', item)"
        >
          <span class="tit_connect">{{ titleKeyName ? item[titleKeyName] : item.title }}</span>
        </button>
        <span v-else class="tit_connect">{{ titleKeyName ? item[titleKeyName] : item.title }}</span>
        <button type="button" class="btn_delete" @click="$emit('onClickRemove', item)">
          <span class="ico_account ico_cross">삭제</span>
        </button>
      </li>
    </ul>
  </Connect>
</template>

<script>
import Connect from "@/components/common/connect/Connect";

export default {
  name: "ConnectEdit",
  components: {
    Connect,
  },
  props: {
    dataList: Array,
    titleKeyName: String,
    disabledData: Object,
  },
  methods: {
    isDisabled(item) {
      const keyNm = Object.keys(this.disabledData)[0];
      const valueNm = Object.values(this.disabledData)[0];
      return item[keyNm] == valueNm;
    },
  },
};
</script>
